<template>
  <div>
    <div class="row p-l-15 p-b-10">
      <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
        <div class="pt-2 p-l-5">
          <span>{{$t("계측기 유형명")}}</span>
        </div>
        <div class="p-l-5">
          <!-- <input :ref="'keyword'" class="form-control" @keydown.enter="onSearch"/> -->
          <valid-input ref="keyword" :inputType="'text'" :classList="'form-control'" @keydownMethod="onSearch" :inputValue.sync="meterTypeName" :rules="rules.EQUIP_TYPE_CODE_SEARCH_NAME_RULE" :NonStatus="true"></valid-input>
        </div>
      </div>
      <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
        <div class="pt-2 p-l-15">
          <span>{{$t("사용여부")}}</span>
        </div>
        <div class="p-l-5">
          <select class="form-control" ref="enabled" @change="onChangeCombo">
            <option value="">{{$t("전체")}}</option>
            <option value="Y">{{$t("사용")}}</option>
            <option value="N">{{$t("사용안함")}}</option>
          </select>
        </div>
      </div>
      <div class="font-weight-bold d-flex flex-row p-l-5" :style="{ fontSize: '15px'}">
        <button class="form-control" @click="onSearch">{{$t("조회")}}</button>
        <button class="form-control ml-1 mr-1" @click="onNew">{{$t("신규")}}</button>
        <!-- <button class="form-control" @click="onRemove">삭제</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import backEndApi from "../../../api/backEndApi";
import * as rules from "@src/consts/ruleConsts.js";
import AppErrorHandler from "@src/appErrorHandler";

export default {
  props: [],
  components: {

  },
  watch: {

  },
  data() {
    return {
      meterTypeCodeList: null,
      meterTypeName: "",
      rules
    }
  },
  async created() {
    // 처음 진입 시에만 호출
    await this.init();
  },
  mounted() {
  },
  methods: {
    async init() {
      // 대분류코드 목록
      await this.getMeterTypeList();
    },
    async getMeterTypeList() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.meterType.getMeterTypeList();
        const data = result.data;

        this.meterTypeCodeList = data;
        this.onSearch();
      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
    // Event
    onChangeCombo() {
      this.onSearch();
    },
    onSearch(){
      var keyword = this.$refs.keyword.value;                     // 대분류코드
      var enabled = this.$refs.enabled.value;                     // 사용여부

      var result = this.meterTypeCodeList
        .filter(data => keyword == "" ? data.meterTypeCodeName == data.meterTypeCodeName : data.meterTypeCodeName.includes(keyword) || keyword == "" ? data.meterTypeCode == data.meterTypeCode : data.meterTypeCode.includes(keyword))
        .filter(data => enabled == "" ? data.enabled == data.enabled : data.enabled == enabled);

      this.$emit("search:data", result);
    },
    async reSearch() {
      this.meterTypeCodeList = null;

      await this.getMeterTypeList();
    },
    onNew() {
      this.$emit("new");
    },

  }
};
</script>

<style scoped>

</style>
