<template>
  <div class="col-xl-12">
    <div class="row">
      <div class="col-lg-12">
        <div class="pull-left">
          <h3> {{$t("코드 관리")}} > {{$t("계측기 코드")}} </h3>
        </div>
      </div>
    </div>

    <hr class="mt-3 mb-3" />

    <div class="row">
      <search-box :ref="'search'" @search:data="onSearch" @new="newModal" />
    </div>

    <b-row :style="{ display: 'contents' }">
      <div class="bg-white col-6" :style="{ padding: 0, minWidth: '850px' }">
        <grid-box :data="items" @reSearch="reSearch" />
      </div>
    </b-row>

    <b-modal ref="newModal" size="lg" :hide-footer="true" :title="$t('신규 계측기 코드')">
      <new-modal @reSearch="reSearch" @hide="hide" />
    </b-modal>
  </div>
</template>

<script>
// import backEndApi from "../../../../api/backEndApi";
import SearchBox from "./SearchBox.vue";
import GridBox from "../../component/gridBox/system-type/meter-type/MeterTypeTable.vue";
import newModal from "./MeterTypeNew.vue";

export default {
  props: [],
  components: {
    SearchBox,
    GridBox,
    newModal,
  },
  watch: {},
  data() {
    return {
      items: null,
    };
  },
  created() {
    // console.log("point management pointMgmt");
  },
  mounted() {},
  methods: {
    onSearch(data) {
      this.items = data;
    },
    newModal() {
      // console.log("new modal");
      this.$refs.newModal.show();
    },
    hide() {
      this.$refs.newModal.hide();
    },
    reSearch() {
      this.$refs.search.reSearch();
    },
  },
};
</script>

<style scoped></style>
